import { createSlice } from "@reduxjs/toolkit";
import { getCutoffsPublicIDInvoiceForClientByOrderISDOCPdfs } from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { Resources, getTranslation } from "Translations/Resources";
import { downloadBase64File } from "Utils/FileUtils";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const getCutoffInvoiceForClientByOrderPdfsAsync = createAsyncAction(
  "@cutoff/GET_INVOICE_FOR_CLIENT_BY_ORDER_PDFS_REQUEST",
  "@cutoff/GET_INVOICE_FOR_CLIENT_BY_ORDER_PDFS_RESPONSE",
  "@cutoff/GET_INVOICE_FOR_CLIENT_BY_ORDER_PDFS_FAILURE",
)<{ cutoffPublicID: string; period: string }, void, Error>();

function* getCutoffInvoiceForClientByOrderPdfs(
  action: ReturnType<typeof getCutoffInvoiceForClientByOrderPdfsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getCutoffsPublicIDInvoiceForClientByOrderISDOCPdfs,
      action.payload.cutoffPublicID,
    );

    if (status === 200) {
      downloadBase64File(
        data.isdocPdfsZipBase64,
        "application/zip",
        `${getTranslation(Resources.Settings.Cutoff.Title)} ${
          action.payload.period
        }.zip`,
      );
      yield put(getCutoffInvoiceForClientByOrderPdfsAsync.success());
      return;
    }

    yield put(
      getCutoffInvoiceForClientByOrderPdfsAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(getCutoffInvoiceForClientByOrderPdfsAsync.failure(err as Error));
  }
}
export function* getCutoffInvoiceForClientByOrderPdfsSaga() {
  yield takeLatest(
    getType(getCutoffInvoiceForClientByOrderPdfsAsync.request),
    getCutoffInvoiceForClientByOrderPdfs,
  );
}

type GetCutoffInvoiceForClientByOrderPdfsSliceState = {
  cutoffPublicID: string | null;
  isLoading: boolean;
  error: Error | null;
};

const initialState: GetCutoffInvoiceForClientByOrderPdfsSliceState = {
  cutoffPublicID: null,
  isLoading: false,
  error: null,
};

export const getCutoffInvoiceForClientByOrderPdfsSlice = createSlice({
  initialState,
  name: "getCutoffInvoiceForClientByOrderPdfs",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(getCutoffInvoiceForClientByOrderPdfsAsync.request),
      (
        state,
        action: ReturnType<
          typeof getCutoffInvoiceForClientByOrderPdfsAsync.request
        >,
      ) => {
        state.cutoffPublicID = action.payload.cutoffPublicID;
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(getCutoffInvoiceForClientByOrderPdfsAsync.success),
      (
        state,
        action: ReturnType<
          typeof getCutoffInvoiceForClientByOrderPdfsAsync.success
        >,
      ) => {
        state.cutoffPublicID = null;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(getCutoffInvoiceForClientByOrderPdfsAsync.failure),
      (
        state,
        action: ReturnType<
          typeof getCutoffInvoiceForClientByOrderPdfsAsync.failure
        >,
      ) => {
        state.cutoffPublicID = null;
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
