import { createSlice } from "@reduxjs/toolkit";
import { getCutoffsPublicIDInvoiceForSupplierCompanyByUserISDOCPdfs } from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { Resources, getTranslation } from "Translations/Resources";
import { downloadBase64File } from "Utils/FileUtils";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const getCutoffInvoiceForSupplierCompanyByUserPdfsAsync =
  createAsyncAction(
    "@cutoff/GET_INVOICE_FOR_SUPPLIER_COMPANY_BY_USER_PDFS_REQUEST",
    "@cutoff/GET_INVOICE_FOR_SUPPLIER_COMPANY_BY_USER_PDFS_RESPONSE",
    "@cutoff/GET_INVOICE_FOR_SUPPLIER_COMPANY_BY_USER_PDFS_FAILURE",
  )<{ cutoffPublicID: string; period: string }, void, Error>();

function* getCutoffInvoiceForSupplierCompanyByUserPdfs(
  action: ReturnType<
    typeof getCutoffInvoiceForSupplierCompanyByUserPdfsAsync.request
  >,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getCutoffsPublicIDInvoiceForSupplierCompanyByUserISDOCPdfs,
      action.payload.cutoffPublicID,
    );

    if (status === 200) {
      downloadBase64File(
        data.isdocPdfsZipBase64,
        "application/zip",
        `${getTranslation(Resources.Settings.Cutoff.Title)} ${
          action.payload.period
        }.zip`,
      );
      yield put(getCutoffInvoiceForSupplierCompanyByUserPdfsAsync.success());
      return;
    }

    yield put(
      getCutoffInvoiceForSupplierCompanyByUserPdfsAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(
      getCutoffInvoiceForSupplierCompanyByUserPdfsAsync.failure(err as Error),
    );
  }
}
export function* getCutoffInvoiceForSupplierCompanyByUserPdfsSaga() {
  yield takeLatest(
    getType(getCutoffInvoiceForSupplierCompanyByUserPdfsAsync.request),
    getCutoffInvoiceForSupplierCompanyByUserPdfs,
  );
}

type GetCutoffInvoiceForSupplierCompanyByUserPdfsSliceState = {
  cutoffPublicID: string | null;
  isLoading: boolean;
  error: Error | null;
};

const initialState: GetCutoffInvoiceForSupplierCompanyByUserPdfsSliceState = {
  cutoffPublicID: null,
  isLoading: false,
  error: null,
};

export const getCutoffInvoiceForSupplierCompanyByUserPdfsSlice = createSlice({
  initialState,
  name: "getCutoffInvoiceForSupplierCompanyByUserPdfs",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(getCutoffInvoiceForSupplierCompanyByUserPdfsAsync.request),
      (
        state,
        action: ReturnType<
          typeof getCutoffInvoiceForSupplierCompanyByUserPdfsAsync.request
        >,
      ) => {
        state.cutoffPublicID = action.payload.cutoffPublicID;
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(getCutoffInvoiceForSupplierCompanyByUserPdfsAsync.success),
      (
        state,
        action: ReturnType<
          typeof getCutoffInvoiceForSupplierCompanyByUserPdfsAsync.success
        >,
      ) => {
        state.cutoffPublicID = null;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(getCutoffInvoiceForSupplierCompanyByUserPdfsAsync.failure),
      (
        state,
        action: ReturnType<
          typeof getCutoffInvoiceForSupplierCompanyByUserPdfsAsync.failure
        >,
      ) => {
        state.cutoffPublicID = null;
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
