import {
  EnterpriseServiceSettingsDto,
  OrderFrequency,
  OrderPeriodicity,
  OrderServiceRate,
  OrderClientStatus,
  OrderPaymentFrequency,
  PartyType,
  IdentificationType,
  OrderPaymentType,
  ServiceVariantProduct,
} from "Api/Api";
import { PartyFormType } from "Components/Shared/Party/PartyForm";
import {
  PartyFormModel,
  getPartyFormValidationsSchema,
} from "Components/Shared/Party/PartyFormValidationSchema";
import { useResource } from "Translations/Resources";
import { formatCurrency } from "Utils/CurrencyUtils";
import { nameof } from "Utils/ObjectUtils";
import {
  ObjectSchema,
  array,
  boolean,
  date,
  number,
  object,
  string,
} from "yup";

export enum FormMode {
  Draft = "Draft",
  Process = "Process",
}

export type ClientFormModel = {
  status: OrderClientStatus;
  type: PartyType;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  taxNumber?: string;
  isVatPayer?: boolean;
  companyNumber?: string;
  street?: string;
  streetNumber?: string;
  postalCode?: string;
  municipality?: string;
  phone?: string;
  email?: string;
};

export type OrderFormModel = {
  mode: FormMode;
  supplierCompanyID: number;
  orderID: number;
  clientID: number;
  note?: string;
  serviceRate: OrderServiceRate;
  hourRateServiceVariantID?: number;
  party: PartyFormModel["party"];
  isClientPersonalDataProcessingConsent?: boolean | null;
  isClientElectronicCommunicationConsent?: boolean | null;
  clientIdentification?: IdentificationType | null;
  clientIdentificationNumber?: string | null;
  order: {
    periodicity: OrderPeriodicity;
    servicePackageType: ServicePackageType;
    frequency: OrderFrequency;
    paymentType: OrderPaymentType;
    paymentPeriodicity: OrderPeriodicity;
    paymentFrequency: OrderPaymentFrequency;
    paymentFrequencyDateFrom?: Date;
    paymentFrequencyDateTo?: Date;
    isIndefiniteEnd: boolean;
  };
  services: ServiceFormModel[];
};

export enum ServicePackageType {
  ProvidedService = "ProvidedService",
  Package = "Package",
}

export type ServiceFormModel = {
  serviceID: number;
  isSelected: boolean;
  serviceVariantID: number;
  hoursSpent: number;
  priceWithoutTax: number;
  priceWithTax: number;
  originalPriceWithoutTax: number;
  originalPriceWithTax: number;
  currencyCode: string;
  serviceDescription: string;
  serviceName: string;
  serviceVariantName: string;
  taxRate: number;
  isEditing: boolean;
  serviceVariantProduct: ServiceVariantProduct;
};

const getServiceValidationSchema = (
  serviceSettings: EnterpriseServiceSettingsDto,
  isFixedOrderServiceRate: boolean,
) => {
  const validationSchema: ObjectSchema<ServiceFormModel> = object({
    serviceID: number().required(),
    isSelected: boolean().required(),
    isEditing: boolean().required(),
    serviceVariantID: number().required(),
    priceWithoutTax: number()
      .required()
      .when(nameof<ServiceFormModel>("isSelected"), {
        is: true,
        then: schema =>
          isFixedOrderServiceRate
            ? schema.min(0).test("isPriceInRange", (value, ctx) => {
                const { maximalPriceRangeRate } = serviceSettings;
                const { originalPriceWithoutTax, currencyCode } =
                  ctx.parent as ServiceFormModel;

                if (!maximalPriceRangeRate) {
                  return true;
                }

                const calculatedMinPrice =
                  originalPriceWithoutTax * (1 - maximalPriceRangeRate);
                const minPrice =
                  calculatedMinPrice > 0 ? calculatedMinPrice : 0;
                const maxPrice =
                  originalPriceWithoutTax * (1 + maximalPriceRangeRate);

                return value >= minPrice && value <= maxPrice
                  ? true
                  : ctx.createError({
                      message: `Minimální cena je ${formatCurrency(
                        minPrice,
                        currencyCode,
                      )} a maximální cena je ${formatCurrency(
                        maxPrice,
                        currencyCode,
                      )}`,
                    });
              })
            : schema,
      }),
    priceWithTax: number()
      .required()
      .when(nameof<ServiceFormModel>("isSelected"), {
        is: true,
        then: schema => (isFixedOrderServiceRate ? schema.min(0) : schema),
      }),
    originalPriceWithoutTax: number()
      .required()
      .when(nameof<ServiceFormModel>("isSelected"), {
        is: true,
        then: schema => (isFixedOrderServiceRate ? schema.min(0) : schema),
      }),
    originalPriceWithTax: number()
      .required()
      .when(nameof<ServiceFormModel>("isSelected"), {
        is: true,
        then: schema => (isFixedOrderServiceRate ? schema.min(0) : schema),
      }),
    hoursSpent: number()
      .required()
      .when(nameof<ServiceFormModel>("isSelected"), {
        is: true,
        then: schema => (isFixedOrderServiceRate ? schema : schema.min(0)),
      }),
    currencyCode: string().required(),
    serviceDescription: string().required(),
    serviceName: string().required(),
    serviceVariantName: string().required(),
    taxRate: number().required(),
    serviceVariantProduct: string()
      .oneOf(Object.values(ServiceVariantProduct))
      .required(),
  }).required();

  return validationSchema;
};

export const useOrderStepFormValidationsSchema = (
  serviceSettings: EnterpriseServiceSettingsDto,
) => {
  const { t } = useResource();

  const validationSchema: ObjectSchema<OrderFormModel> = object({
    mode: string().oneOf(Object.values(FormMode)).required(),
    supplierCompanyID: number().required(),
    orderID: number().required(),
    clientID: number().required(),
    isClientElectronicCommunicationConsent: boolean().nullable(),
    isClientPersonalDataProcessingConsent: boolean().nullable(),
    clientIdentification: string<IdentificationType>().when(
      nameof<OrderFormModel>("isClientElectronicCommunicationConsent"),
      {
        is: true,
        then: schema =>
          schema
            .oneOf<IdentificationType>(Object.values(IdentificationType))
            .required(),
        otherwise: schema => schema.optional().nullable(),
      },
    ),
    clientIdentificationNumber: string().when(
      nameof<OrderFormModel>("isClientElectronicCommunicationConsent"),
      {
        is: true,
        then: schema => schema.required(),
        otherwise: schema => schema.optional().nullable(),
      },
    ),
    note: string().optional(),
    serviceRate: string().oneOf(Object.values(OrderServiceRate)).required(),
    hourRateServiceVariantID: number().when(nameof<OrderFormModel>("mode"), {
      is: FormMode.Process,
      then: schema =>
        schema.when(nameof<OrderFormModel>("serviceRate"), {
          is: OrderServiceRate.HourRate,
          then: schema => schema.required(),
          otherwise: schema => schema.optional().nullable(),
        }),
      otherwise: schema => schema.optional().nullable(),
    }),
    party: object()
      .when([nameof<OrderFormModel>("mode")], ([mode], schema) =>
        getPartyFormValidationsSchema(
          t,
          mode === FormMode.Process,
          false,
          PartyFormType.OrderClient,
        ),
      )
      .required() as ObjectSchema<ClientFormModel>,
    order: object({
      periodicity: string().oneOf(Object.values(OrderPeriodicity)).required(),
      servicePackageType: string()
        .oneOf(Object.values(ServicePackageType))
        .required(),
      frequency: string().oneOf(Object.values(OrderFrequency)).required(),
      isIndefiniteEnd: boolean().required(),
      paymentPeriodicity: string()
        .oneOf(Object.values(OrderPeriodicity))
        .required(),
      paymentFrequency: string()
        .oneOf(Object.values(OrderPaymentFrequency))
        .required(),
      paymentType: string().oneOf(Object.values(OrderPaymentType)).required(),
      paymentFrom: date().when(nameof<OrderFormModel["order"]>("frequency"), {
        is: OrderPaymentFrequency.Single,
        then: schema => schema.required(),
        otherwise: schema => schema.optional().nullable(),
      }),
      paymentTo: date().when(nameof<OrderFormModel["order"]>("frequency"), {
        is: OrderPaymentFrequency.Single,
        then: schema => schema.required(),
        otherwise: schema => schema.optional().nullable(),
      }),
    }).required(),
    services: array()
      .when(nameof<OrderFormModel>("serviceRate"), {
        is: OrderServiceRate.FixedPrice,
        then: schema =>
          schema.of(getServiceValidationSchema(serviceSettings, true)),
        otherwise: schema =>
          schema.of(getServiceValidationSchema(serviceSettings, false)),
      })
      .required()
      .test("isAtLeastOneServiceSelected", (value, ctx) => {
        const isAtLeastOneServiceSelected = value?.some(
          service => service.isSelected,
        );

        return isAtLeastOneServiceSelected ||
          (ctx.parent as OrderFormModel).mode === FormMode.Draft
          ? true
          : ctx.createError({
              message: "Musíte vybrat alespoň jednu službu",
            });
      }),
  }).defined();

  return validationSchema;
};
