import { Typography } from "@mui/material";
import { CutoffListItemDto } from "Api/Api";
import { BlDefaultButton } from "Components/Shared/Buttons/BlDefaultButton";
import { BlDataTable } from "Components/Shared/DataTable/BlDataTable";
import {
  ContractIcon,
  GenerateIcon,
  ListLayoutIcon,
} from "Components/Shared/Icons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { cutoffSendPaymentsAsync } from "State/Cutoff/CutoffSendPayments";
import { getCutoffDetailPdfAsync } from "State/Cutoff/GetCutoffDetailPdf";
import { getCutoffInvoiceForSupplierCompanyByUserPdfsAsync } from "State/Cutoff/GetCutoffInvoiceForSupplierCompanyByUserPdfsSlice";
import { getCutoffInvoiceForClientByOrderPdfsAsync } from "State/Cutoff/GetCutoffInvoiceForClientByOrderPdfsSlice";
import { getCutoffsListAsync } from "State/Cutoff/GetCutoffsListSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { formatDate } from "Utils/DateUtils";
import { useEffect, useState } from "react";
import { styled } from "styled-components";

const StyledValue = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const CutoffsList: React.FunctionComponent = _ => {
  const { t } = useResource();
  const dispatch = useAppDispatch();

  const [filter, setFilter] = useState({
    limit: 25,
    offset: 0,
  });

  useEffect(() => {
    dispatch(
      getCutoffsListAsync.request({
        ...filter,
      }),
    );
  }, [dispatch, filter]);

  const onPageChange = (page: number, rows: number) => {
    setFilter({ ...filter, offset: page * rows, limit: rows });
  };

  const { data, isLoading } = useAppSelector(state => state.cutoff.list);

  const zipCutoffInvoiceForSupplierCompanyByUserState = useAppSelector(
    state => state.cutoff.zipCutoffInvoiceForSupplierCompanyByUserPdfsSlice,
  );

  const zipCutoffInvoiceForOrderByUserState = useAppSelector(
    state => state.cutoff.zipCutoffInvoiceForClientByOrderPdfsSlice,
  );
  const pdfState = useAppSelector(state => state.cutoff.pdf);
  const sendPaymentsState = useAppSelector(state => state.cutoff.sendPayments);

  const formatPeriodDate = (row: CutoffListItemDto) =>
    `${formatDate(row.dateFrom)} - ${formatDate(row.dateTo)}`;

  return (
    <>
      <StyledFlex $gap={0.5} $alignItems="center">
        <Typography variant="h2">
          {t(Resources.Settings.Cutoff.List.Title)}
        </Typography>
      </StyledFlex>

      <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={2}>
        {t(Resources.Settings.Cutoff.List.Description)}
      </Typography>

      <BlDataTable
        isLoading={isLoading}
        totalCount={data?.totalCount}
        rows={data?.items.map(e => ({ data: e })) ?? []}
        rowEntityID="publicID"
        getHeaderCell={column => {
          return <div>{column.label ?? column.id}</div>;
        }}
        onPageChange={onPageChange}
        skeletonRowsCount={5}
        columns={[
          {
            id: "dateFrom",
            label: t(Resources.Settings.Cutoff.List.DateFrom),
          },
          {
            id: "companyName",
            label: t(Resources.Settings.Cutoff.List.CompanyName),
          },
          {
            id: "dateProcessed",
            label: t(Resources.Settings.Cutoff.List.DateProcessed),
          },
          { id: "action", label: "", maxWidth: 0, justifySelf: "flex-end" },
        ]}
        getCell={(column, row) => {
          switch (column) {
            case "dateFrom":
              return (
                <Typography fontWeight={600}>
                  {formatPeriodDate(row)}
                </Typography>
              );

            case "companyName":
              return (
                <div>
                  <Typography fontWeight={600}>{row.companyName}</Typography>
                  <Typography>
                    {t(Resources.Common.CompanyNumber)}: {row.companyNumber}
                  </Typography>
                  {row.taxNumber && (
                    <Typography>
                      {t(Resources.Common.TaxNumber)}: {row.taxNumber}
                    </Typography>
                  )}
                </div>
              );

            case "dateProcessed":
              return (
                <StyledValue>{formatDate(row.dateProcessed, true)}</StyledValue>
              );

            case "action":
              return (
                <StyledFlex $alignItems={"center"} $gap={2.5}>
                  <BlDefaultButton
                    title={t(Resources.Settings.Cutoff.List.DownloadPdf)}
                    isLoading={
                      pdfState.isLoading &&
                      pdfState.cutoffPublicID === row.publicID
                    }
                    onClick={() =>
                      dispatch(
                        getCutoffDetailPdfAsync.request({
                          cutoffPublicID: row.publicID,
                          period: formatPeriodDate(row),
                        }),
                      )
                    }
                    isSquare
                  >
                    <ListLayoutIcon />
                  </BlDefaultButton>

                  <BlDefaultButton
                    title={t(
                      Resources.Settings.Cutoff.List
                        .DownloadZipInvoiceForCompanyByUser,
                    )}
                    isLoading={
                      zipCutoffInvoiceForSupplierCompanyByUserState.isLoading &&
                      zipCutoffInvoiceForSupplierCompanyByUserState.cutoffPublicID ===
                        row.publicID
                    }
                    onClick={() =>
                      dispatch(
                        getCutoffInvoiceForSupplierCompanyByUserPdfsAsync.request(
                          {
                            cutoffPublicID: row.publicID,
                            period: formatPeriodDate(row),
                          },
                        ),
                      )
                    }
                    isSquare
                  >
                    <ContractIcon />
                  </BlDefaultButton>

                  <BlDefaultButton
                    title={t(
                      Resources.Settings.Cutoff.List
                        .DownloadZipInvoiceForClientByOrder,
                    )}
                    isLoading={
                      zipCutoffInvoiceForOrderByUserState.isLoading &&
                      zipCutoffInvoiceForOrderByUserState.cutoffPublicID ===
                        row.publicID
                    }
                    onClick={() =>
                      dispatch(
                        getCutoffInvoiceForClientByOrderPdfsAsync.request({
                          cutoffPublicID: row.publicID,
                          period: formatPeriodDate(row),
                        }),
                      )
                    }
                    isSquare
                  >
                    <ContractIcon />
                  </BlDefaultButton>

                  <BlDefaultButton
                    title={t(Resources.Settings.Cutoff.List.GeneratePayments)}
                    isLoading={
                      sendPaymentsState.isLoading &&
                      sendPaymentsState.cutoffPublicID === row.publicID
                    }
                    disabled={!row.isSendingPaymentsEnabled}
                    isSquare
                    onClick={() => {
                      if (!row.isSendingPaymentsEnabled) {
                        return;
                      }

                      return dispatch(
                        cutoffSendPaymentsAsync.request({
                          cutoffPublicID: row.publicID,
                        }),
                      );
                    }}
                  >
                    <GenerateIcon />
                  </BlDefaultButton>

                  <div></div>
                </StyledFlex>
              );

            default:
              const value = (row as any)[column as any] ?? "";

              return <div>{value}</div>;
          }
        }}
      />
    </>
  );
};
